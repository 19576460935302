@import "./src/mypage/scss/tek-settings.scss";





































//////////////////////////////////////////////////////////////////////////////
// overrides
//////////////////////////////////////////////////////////////////////////////

// expand div created by slick
/deep/ .slick-slide > div {
    width: 100%;
    margin: 0 1rem;
    display: flex!important;
}
/deep/ .slick-slide {
    display: flex!important;
}

/deep/ .slick-slider {
    margin-left: -1rem;
    margin-right: -1rem;
}


/deep/ {
    .slick-prev:before, .slick-next:before {
        content: '';
        height: 42px;
        width: 42px;
        display: block;
        opacity: 1;
        transition: all .2s ease;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCA0MiA0MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjM3NSAxMUwyNi4zNzUgMjFMMTYuMzc1IDMxIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8Y2lyY2xlIHI9IjIwLjUiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDIxIDIxKSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLW9wYWNpdHk9IjAuNCIvPgo8L3N2Zz4K");
    }
}

/deep/ .slick-prev {
    left: auto;
    right: 83px;
    top: auto;
    bottom: -44px;

    &:before {
        transform: scaleX(-1);
    }
}

/deep/ .slick-next {
    //left: auto;
    right: 33px;
    top: auto;
    bottom: -44px;
}

/deep/ {
    .slick-prev:hover:before, .slick-next:hover:before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCA0MiA0MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjM3NSAzMUwyNi4zNzUgMjFMMTYuMzc1IDExIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8Y2lyY2xlIGN4PSIyMSIgY3k9IjIxIiByPSIyMC41IiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo=");
    }
}


/deep/ .slick-list {
    padding: 0 !important;
}

/deep/ .slick-dots li button:before {
    font-size: 9px;
    color: $gray-300;
    opacity: 1;

}

/deep/ .slick-dots li.slick-active button:before {
    color: var(--dark-blue);
    opacity: 1;
}

/deep/ .slick-dots {
    bottom: -2rem;
}

//////////////////////////////////////////////////////////////////////////////
// end overrides
//////////////////////////////////////////////////////////////////////////////

.tek-carousel {
    margin-top: 2rem;
    margin-bottom: 7rem;
    @include media-breakpoint-down(sm) {
        margin-bottom: 4rem;
    }
    &-title {
        margin-bottom: 1rem;
        margin-top: 0;
        font-size: $h3-font-size;
}
}

.tek-carousel-item {
    position: relative;
    min-height: 142px;
    background: rgba(0, 0, 0, .04);
    background: var(--gray-100);
    cursor: pointer;
    transition: all .2s ease;

    .tek-carousel-item__intro  {
        text-decoration-color: rgba(0, 0, 0, 0);

    }
    &:hover {
        .tek-carousel-item__intro {
            text-decoration-color: rgba(0, 0, 0, 1);
        }
    }
    .tek-carousel-item__link {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &--chosen {
        background: var(--dark-blue);
        color: #fff;
        & .tek-carousel-item__date {
            color: $gray-300;
        }
        .icon-arrow:before {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNC4zNTQgMTUuMTQ2TDE4LjM1NCA5LjE0NjAxQzE4LjE1OSA4Ljk1MTAxIDE3Ljg0MiA4Ljk1MTAxIDE3LjY0NyA5LjE0NjAxQzE3LjQ1MiA5LjM0MTAxIDE3LjQ1MiA5LjY1ODAxIDE3LjY0NyA5Ljg1MzAxTDIyLjc5MyAxNC45OTlINi41QzYuMjI0IDE0Ljk5OSA2IDE1LjIyMyA2IDE1LjQ5OUM2IDE1Ljc3NSA2LjIyNCAxNS45OTkgNi41IDE1Ljk5OUgyMi43OTNMMTcuNjQ3IDIxLjE0NUMxNy40NTIgMjEuMzQgMTcuNDUyIDIxLjY1NyAxNy42NDcgMjEuODUyQzE3Ljc0NSAyMS45NSAxNy44NzMgMjEuOTk4IDE4LjAwMSAyMS45OThDMTguMTI5IDIxLjk5OCAxOC4yNTcgMjEuOTQ5IDE4LjM1NSAyMS44NTJMMjQuMzU1IDE1Ljg1MkMyNC41NSAxNS42NTcgMjQuNTUgMTUuMzQgMjQuMzU1IDE1LjE0NUwyNC4zNTQgMTUuMTQ2WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==")
        }
        .tek-carousel-item__intro a {
            text-decoration-color: rgba(255, 255, 255, 0);
        }
        &:hover {
            .tek-carousel-item__intro a {
                text-decoration-color: rgba(255, 255, 255, 1);
            }
        }

    }

    &:hover {
        & .icon-arrow {
            right: 19px;
        }
    }
}

.icon-arrow {
    display: none;
    @include media-breakpoint-up(xl){
        display: block;
    }
    position: absolute;
    right: 1.5rem;
    top: 74%;
    transition: right .2s ease;

    &:before {
        content: '';
        height: 30px;
        width: 30px;
        display: block;
        transition: all .2s ease;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNC4zNTQgMTUuMTQ2TDE4LjM1NCA5LjE0NjAxQzE4LjE1OSA4Ljk1MTAxIDE3Ljg0MiA4Ljk1MTAxIDE3LjY0NyA5LjE0NjAxQzE3LjQ1MiA5LjM0MTAxIDE3LjQ1MiA5LjY1ODAxIDE3LjY0NyA5Ljg1MzAxTDIyLjc5MyAxNC45OTlINi41QzYuMjI0IDE0Ljk5OSA2IDE1LjIyMyA2IDE1LjQ5OUM2IDE1Ljc3NSA2LjIyNCAxNS45OTkgNi41IDE1Ljk5OUgyMi43OTNMMTcuNjQ3IDIxLjE0NUMxNy40NTIgMjEuMzQgMTcuNDUyIDIxLjY1NyAxNy42NDcgMjEuODUyQzE3Ljc0NSAyMS45NSAxNy44NzMgMjEuOTk4IDE4LjAwMSAyMS45OThDMTguMTI5IDIxLjk5OCAxOC4yNTcgMjEuOTQ5IDE4LjM1NSAyMS44NTJMMjQuMzU1IDE1Ljg1MkMyNC41NSAxNS42NTcgMjQuNTUgMTUuMzQgMjQuMzU1IDE1LjE0NUwyNC4zNTQgMTUuMTQ2WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==")
    }
}


.tek-carousel-item__img {
    max-height: 99px;
    margin: 1.25rem;
}

.tek-carousel-item__date {
    font-size: $font-size-sm;
    margin-top: 1rem;
    color: $gray-600;
}

.tek-carousel-item__intro  {
    font-size: $font-size-body2;
    //width: 207px;
    width: 78%;
    //margin: 0;
    margin-bottom: 1rem;
}

